import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../components/seo'
import NewsSectionBlock from '../components/NewsSectionBlock'
import Typography from '../components/Common/Typography'

import * as s from '../pages/news.module.scss'

const SignaxOnCtfPage: React.FC = () => {
  return (
    <>
      <SEO
        title="SIGNAX On CTF"
        description="SIGNAX on Construction Technology Festival"
      />
      <NewsSectionBlock date="09.06.2022">
        <Typography variant="h1" color="blue">
          SIGNAX on Construction Technology Festival
        </Typography>
        <div className={s.authorWrapper}>
          <StaticImage
            src="../assets/images/news/signax-on-ctf/avatar.png"
            alt="Roman Ruksha"
            title="Roman Ruksha"
            placeholder="blurred"
          />
          <div className="flex flex-col ml-4">
            <Typography variant="body2" color="blue" size={24}>
              Roman Ruksha
            </Typography>
            <Typography variant="body2" color="gray" size={16} className="mt-2">
              Account Director MENA
            </Typography>
          </div>
        </div>
        <Typography variant="body1">
          I am delighted and honored to present our SIGNAX solution during the
          #ConstructionTechnologyFestival in Dubai, UAE. It was the right place
          to discuss the latest Digital Construction Technologies with the
          forefront Middle East experts and share our vision, experience and
          expertise. We’ve received a strong and useful response of what we’ve
          got to offer to this market from #Autodesk, Bentley Digital Cities,
          ALEC, ARUP, IHCC, Urban Surveys, AJCO, Khatib & Alami and WakeCap.
          We’re looking forward to continue our communication and bring the
          maximum value and efficiency through the implementing BIM on the
          construction site.
        </Typography>
        <div className={s.imageWrap}>
          <StaticImage
            width={720}
            src="../assets/images/news/signax-on-ctf/preview.png"
            alt="Construction Technology Festival"
            title="Construction Technology Festival"
            placeholder="blurred"
          />
        </div>
      </NewsSectionBlock>
    </>
  )
}

export default SignaxOnCtfPage
